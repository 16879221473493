@import 'Colors.css';

#sidebar {
    top: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh !important;
    z-index: 100;
    color: var(--text-white);
  }

  .sidebar-container {
    background-color: var(--dark-grey);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    position: relative;
    text-align: center;
  }
  
  #mainLogo {
    width: 100px;
  }

  .navItem {
    font-size: small;
    font-weight: 100;
    display: block;
    padding: 10px 0;
    text-decoration: none;
    color: var(--text-white);
}

#sidebar a.active {
    background-color: var(--primary);
    color: var(--dark-grey);
    font-weight: 400;
}

#sidebar a.active:Hover {
  background-color: var(--primary);
  color: var(--dark-grey);
  font-weight: 400;
}

#sidebar a:hover {
    color: var(--text-white);
    background-color: var(--nav-hover);
}

.vh-calc {
  height: calc(100vh - 25px);
  position: sticky;
  top: 0;
}

.mobile-nav-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.mobile-nav-container {
  background-color: var(--dark-grey);
  padding: 12px !important;
  border-bottom: 1px solid var(--primary);
}

.toggle-button-wrapper {
  text-align: right;
}

.mobile-margin-top {
  margin-top: 0px;
}

.mobile-nav-desclaimer {
  font-size: 0.6rem;
  font-weight: 100;
  bottom: 25px;
  padding: 0 15px;
  margin: 25px 0;
  text-align: center;
}

.mobile-nav-container  a.active {
  background-color: var(--primary);
  border-radius: 20px;
  color: var(--text-white);
}

.mobile-nav-desclaimer a {
  color: var(--primary);
  font-weight: 700;
  text-decoration: none;
}

.mobile-nav-desclaimer span {
  font-weight: 700;
}