@import 'Colors.css';

.top-info-bar {
  background-color: var(--primary);
  border-radius: 15px;
  padding: 15px 25px;
  margin-bottom: 25px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
}

.accordion {
  --bs-accordion-color: var(--text-white) !important;
  --bs-accordion-bg: var(--primary) !important;
  --bs-accordion-border-width: none !important;
  border-radius: 15px !important;
  --bs-accordion-btn-color: var(--text-white) !important;
  --bs-accordion-btn-focus-border-color: none !important;
  --bs-accordion-btn-focus-box-shadow: none !important;
  --bs-accordion-active-color: none !important;
  --bs-accordion-active-bg: var(--primary) !important;
  margin-bottom: 25px;
}

.accordion-body {
  padding-top: 0 !important;
}

.accordion-item {
  border-radius: 15px !important;
}

.accordion-button {
  border-radius: 15px !important;
}

.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23d4d8e3'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.rounded-container {
  border-radius: 15px;
  padding: 15px 25px;
  width: 100%;
  margin-bottom: 25px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
}

.pokemon-main-sprite {
  width: 100%;
  max-width: 300px;
}

.chart-wrapper {
  min-height: 150px;
  max-height: 200px;
  min-width: 100%;
}

.line-chart-wrapper {
  min-height: 300px;
  max-height: 500px;
}

.form-check-input {
  font-size: 20px;
}

.form-check-label {
  margin-top: 5px;
}

/* Search Box ================== */
.input-box {
  position: relative;
  z-index: 100;
}

.search-bar {
  background-color: var(--dark-grey);
  border-radius: 25px;
  border: none;
  height: 45px;
  width: 100%;
  color: var(--text-white);
  margin-bottom: 25px;
  padding: 5px 25px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
}

.search-bar:focus {
border: none;
outline: 0;
}

.search-bar.has-value {
  border: 1px solid var(--mid-grey);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
}

.searchBarResults {
  background-color: var(--dark-grey);
  position: absolute;
  width: 100%;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--mid-grey);
}

.searchBarResults img{
  height: 60px;
}

.searchBarResults .row:hover{
  background-color: var(--mid-grey);
}

.searchBarResults .row {
  cursor: pointer;
  padding: 0 25px;
  height: 60px;
  vertical-align: middle;
}

.searchBarResults div {
  height: 65px;
  line-height: 65px;
}

.form-check-input {
  background-color: var(--mid-grey) !important;
  border: 1px solid rgba(0,0,0,.25);
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e") !important;
}

.form-check-input:focus {
  border: none !important;
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.pokemon-type {
  border-radius: 10px;
  padding: 2px 10px;
  margin: 0 5px !important;
  text-shadow:1px 1px 5px #000000;
  font-size: 0.75rem;
}

.shiny-text {
  color: var(--primary);
}

/*================================== */

/* Weakness Table ================== */

table tr td {
  text-align: center;
  color: var(--text-white);
}

tr, td, th {
  border: 1px solid var(--mid-grey) !important;
  font-size: 10px;
}

td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.weakness-item {
  width: 11%;
}

.dark-text {
  color: var(--dark-grey);
}

/*================================== */

/* Pie Chart ======================= */

#pieChartName span:nth-of-type(1) {
  color: var(--chart1);
  font-weight: 700;
}

#pieChartName span:nth-of-type(2) {
  color: var(--chart2);
  font-weight: 700;
}

#pieChartName span:nth-of-type(3) {
  color: var(--chart3);
  font-weight: 700;
}

#pieChartName span:nth-of-type(4) {
  color: var(--chart4);
  font-weight: 700;
}

.item-legend:before {
  content:"\A";
  margin-right: 15px;
  width:10px;
  height:10px;
  border-radius:50%;
  display:inline-block;
}

.item-legend:nth-of-type(1)::before {
  background: var(--chart1);
}

.item-legend:nth-of-type(2)::before {
  background: var(--chart2);
}

.item-legend:nth-of-type(3)::before {
  background: var(--chart3);
}

.item-legend:nth-of-type(4)::before {
  background: var(--chart4);
}

/* ================================= */

.radar-size {
  width: 100%;
}

/* Seachbar */

#seachBarTable tr {
  cursor: pointer;
}

/* Evolution */

.evolutionContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.evolutionContainer .row {
  width: 100%;
}

.evolutionContainer .row div {
  width: 50%;
  text-align: center;
}

.evolutionContainer img {
  width: 100px;
  height: 100px;
  margin: 10px;
}

.evolutionContainer img:hover {
  transform: scale(1.1);
}

.pointer {
  cursor: pointer;
}

.pointer:hover {
  color: var(--primary);
}

.evolution {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.evolution img {
  cursor: pointer;
}

.disclaimer {
  font-size: 0.6rem;
  font-weight: 100;
  text-align: center;
}

.disclaimer a {
  color: var(--primary);
  font-weight: 700;
  text-decoration: none;
}

.disclaimer span {
  font-weight: 700;
}