@import 'Colors.css';
@import 'Sidebar.css';
@import 'Styles.css';

html, body {
  height:100%;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  color: var(--text-white) !important;
  font-size: small !important;
}

h1, h2, h3, p, a, span {
  margin: 0 !important;
}

h1 {
  font-size: 1.2rem !important;
}

h2 {
  font-size: 1.25rem !important;
}

h3 {
  font-size: 1rem !important;
}

.bold {
  font-weight: 700;
}

/* Utilities */
.p-0 {
  padding: 0 !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.plr-25 {
  padding: 0 25px;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-12-reset {
  margin-bottom: 12px !important;
}

.h-100 {
  height: 100%;
}

.capitalize {
  text-transform: capitalize !important;
}

.sm-m-auto {
  margin: 0;
}

.responsive-width {
  width: 100%;
}

@media only screen and (max-width: 576px) {
  .xs-text-center {
    text-align: center;
  }

  .rounded-container {
    margin-bottom: 12px;
  }

  .top-info-bar {
    margin-bottom: 12px;
  }

  .search-bar {
    margin-bottom: 12px;
  }

  .chart-wrapper {
    min-height: 100px;
    max-height: 200px;
  }

  .radar-size {
    width: 75%;
  }

  .sm-m-auto {
    margin: 0;
  }

  .responsive-width {
    width: 50%;
  }

  td {
    padding: 5px 0 !important;
  }
}

@media only screen and (max-width: 768px) {
  .radar-size {
    width: auto;
  }

  .sm-m-auto {
    margin: 0;
  }

  .responsive-width {
    width: 75%;
  }

  .mobile-margin-top {
    margin-top: 64px;
  }
}

@media only screen and (max-width: 992px) {

  .sm-m-auto {
    margin: auto;
  }

  .responsive-width {
    width: 75%;
    height: 250px;
  }
}

@media only screen and (min-width: 992px) {

  .sm-m-auto {
    margin: auto;
  }

  .responsive-width {
    width: 75%;
    height: 200px;
  }
}

/* navbar display  */

@media only screen and (min-width: 576px) {

  .top-spacing {
    margin-top: 65px !important;
  }

  .d-sidebar {
    display: none !important;
  }

  .d-mobile {
    display: block !important;
  }
}

@media only screen and (max-width: 576px) {
  .top-spacing {
    margin-top: 65px !important;
  }

  .d-sidebar {
    display: none !important;
  }

  .d-mobile {
    display: block !important;
  }
}

@media only screen and (min-width: 768px) {
  .top-spacing {
    margin-top: 65px !important;
  }

  .d-sidebar {
    display: none !important;
  }

  .d-mobile {
    display: block !important;
  }
}

@media only screen and (min-width: 992px) {
  .top-spacing {
    margin-top: 65px !important;
  }

  .d-sidebar {
    display: none !important;
  }

  .d-mobile {
    display: block !important;
  }
}

@media only screen and (min-width: 1200px) {
  .top-spacing {
    margin-top: 0 !important;
  }
  
  .d-sidebar {
    display: block !important;
  }

  .d-mobile {
    display: none !important;
  }
}